import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useReducer,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { SzDatePicker, SzAccordion } from 'react-theme-components';
import { Layout } from '../components/Layout';
import { ComponentWithTitle } from '../components/Common';
import { Co2, IndexValue, Revenue, Volume } from '../components/Chart/Analysis';
import { Currency, Token } from '../helpers';
import { useLogged } from '../hooks';
import { BaseStoreContext, ChartProvider, LanguageContext } from '../contexts';
import { OptionInterface } from '../components/Filters';
import { Co2ChartProvider } from '../contexts/Co2ChartContext';

import {
  MixedChartContext,
  MixedChartProvider,
} from '../contexts/MixedChartContext';
import {
  VolumeChartProvider,
} from '../contexts/VolumeChartContext';
import { TypeSelector, TypeSelectorProvider } from '../components/TypeSelector';
import { ExportAllChart } from '../components/Button';
import { FilterMaterials } from '../components/Chart';
import { CircularEconomy } from '../components/Chart/Analysis/CircularEconomy';
import { CircularEconomyChartProvider } from '../contexts/CircularEconomyChartContext';
import { ExportIndexButton } from '../components/Button/ExportIndexButton';
import { BaseChartsInterface, ChartInterface, ChartsInterface, MultiChartProviderInterface, PeriodeInterface, VolumeChartInterface } from '../interfaces/ChartInterface';


const volumeCharts: (MultiChartProviderInterface & BaseChartsInterface)[] = [
  {
    component: <Volume />,
    title: 'pages.analysis.volume',
    icon: 'poll',
    options: {
      Tonnages: {
        label: 'charts.volume.label',
        color: '#030f40',
        sum: true,
        unit: 't',
      },
      Unites: {
        label: 'charts.volume_units.label',
        color: '#77B7FC',
        type: 'line',
      },
    },
  },
];

const co2Charts: ChartsInterface[] = [
  {
    component: <Co2 />,
    label: 'charts.co2.label',
    title: 'pages.analysis.co2',
    icon: 'trending_up',
  },
];

const mixedCharts: ChartsInterface[] = [
  {
    component: <IndexValue />,
    label: 'charts.index.label',
    title: 'pages.analysis.indexes',
    icon: 'trending_up',
  },
];

const circularEconomyCharts: (MultiChartProviderInterface &
  BaseChartsInterface)[] = [
  {
    component: <CircularEconomy />,
    title: 'pages.analysis.circularEconomy',
    icon: 'sync',
    options: {
      Automotive: {
        label: 'charts.circularEconomy.automotive',
        color: '#FFD469',
        sum: true,
      },
      Construction: {
        label: 'charts.circularEconomy.construction',
        color: '#F89E73',
        sum: true,
      },
      Railway: {
        label: 'charts.circularEconomy.railway',
        color: '#8187A0',
        sum: true,
      },
      Recycling: {
        label: 'charts.circularEconomy.recycling',
        color: '#C4E769',
        sum: true,
      },
      Aeronotic: {
        label: 'charts.circularEconomy.aeronotic',
        color: '#77B7FC',
        sum: true,
      },
      scrapRetail: {
        label: 'charts.circularEconomy.scrapRetail',
        color: '#B4B7C6',
        sum: true,
      },
      transport: {
        label: 'charts.circularEconomy.transport',
        color: '#BBDCFD',
        sum: true,
      },
      oilAndGas: {
        label: 'charts.circularEconomy.oilAndGas',
        color: '#030F40',
        sum: true,
      },
      energy: {
        label: 'charts.circularEconomy.energy',
        color: '#AADC14',
        sum: true,
      },
      varnishesPowderPaint: {
        label: 'charts.circularEconomy.varnishesPowderPaint',
        color: '#4F5779',
        sum: true,
      },
      tractorVehicle: {
        label: 'charts.circularEconomy.tractorVehicle',
        color: '#FFC229',
        sum: true,
      },
      Other: {
        label: 'charts.circularEconomy.other',
        color: '#666666',
        sum: true,
      },
      NA: {
        label: 'NA',
        color: '#666666',
        sum: true,
      },
    },
  },
];

const Chart: React.FC<VolumeChartInterface & PeriodeInterface> = ({
  chart,
  startDate,
  endDate,
  children,
}) => (
  <ChartProvider {...chart} startDate={startDate} endDate={endDate}>
    <ComponentWithTitle {...chart}>
      <div className="col-12 pt-2 px-0 px-md-3">{children}</div>
    </ComponentWithTitle>
    <div className="dropdown-divider col-12 my-4" />
  </ChartProvider>
);

const VolumeChart: React.FC<VolumeChartInterface & PeriodeInterface> = ({
  chart,
  startDate,
  endDate,
  children,
}) => (
  <VolumeChartProvider {...chart} startDate={startDate} endDate={endDate}>
    <ComponentWithTitle {...chart}>
      <div className="col-12 pt-2 px-0 px-md-3">{children}</div>
    </ComponentWithTitle>
    <div className="dropdown-divider col-12 my-4" />
  </VolumeChartProvider>
);

const Co2Chart: React.FC<ChartInterface & PeriodeInterface> = ({
  chart,
  startDate,
  endDate,
  children,
}) => {
  const { translate } = useContext(LanguageContext);

  const labelsLegendCO2 = [
    'aluminiumTeqCO2',
    'brassTeqCO2',
    'bronzeTeqCO2',
    'cardboardTeqCO2',
    'castIronTeqCO2',
    'copperTeqCO2',
    'E1TeqCO2',
    'E2TeqCO2',
    'E3TeqCO2',
    'E5TeqCO2',
    'E6TeqCO2',
    'E8TeqCO2',
    'EHRTeqCO2',
    'ferrousTeqCO2',
    'nonferrousTeqCO2',
    'paperTeqCO2',
    'plasticsTeqCO2',
    'titaniumTeqCO2',
    'VFTeqCO2',
  ];

  const [activeKey, setActiveKey] = useState('1');
  const onClick = (key) => setActiveKey(key !== activeKey ? key : -1);

  const finalLegendCO2 = useMemo(
    () =>
      labelsLegendCO2.filter(
        (item) =>
          translate(`pages.analysis.co2.legendCO2.${item}`) !==
          `pages.analysis.co2.legendCO2.${item}`
      ),
    [labelsLegendCO2]
  );
  const indexLegend =
    Math.floor(finalLegendCO2.length / 2) + (finalLegendCO2.length % 2);

  return (
    <Co2ChartProvider {...chart} startDate={startDate} endDate={endDate}>
      <ComponentWithTitle {...chart}>
        <div className="col-12 pt-2 px-3 px-md-3">
          {children}
          <div className="bg-white py-3 container">
            <div className="col-12 py-2">
              <span className="text-muted">
                {translate('pages.analysis.co2.disclaimer')}
              </span>
            </div>
            <div className="col-12 py-1">
              <SzAccordion variant="details" activeKey={activeKey}>
                <SzAccordion.SzCollapse
                  header={translate('pages.analysis.co2.co2Details')}
                  body={
                    <div className="row py-2">
                      {[0, 1].map((i, k) => (
                        <div key={k} className="col-6">
                          <ul>
                            {finalLegendCO2.map(
                              (item, key) =>
                                ((i === 0 && key < indexLegend) ||
                                  (i === 1 && key > indexLegend - 1)) && (
                                  <li key={key}>
                                    {translate(
                                      `pages.analysis.co2.legendCO2.${item}`
                                    )}
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      ))}
                    </div>
                  }
                  eventKey="0"
                  onClick={onClick}
                />
              </SzAccordion>
            </div>
          </div>
        </div>
      </ComponentWithTitle>
      <div className="dropdown-divider col-12 my-4" />
    </Co2ChartProvider>
  );
};

const MixedChart: React.FC<ChartInterface> = ({ chart, children }) => {
  const { selectedIndex } = useContext(MixedChartContext);
  const { translate } = useContext(LanguageContext);

  return (
    <ComponentWithTitle {...chart} button={<ExportIndexButton />}>
      <div className="col-12 pt-2 px-0 px-md-3">
        {children}
        {selectedIndex?.label === 'MMR-SteelMint-SIAM' && (
          <div className="bg-white py-3 container">
            <div className="col-12 py-2">
              <span className="text-muted">
                {translate("pages.analysis.indexes.MMR")}
              </span>
            </div>
          </div>
        )}
      </div>
    </ComponentWithTitle>
  );
};
const CircularEconomyChart: React.FC<
  VolumeChartInterface & PeriodeInterface
> = ({ chart, startDate, endDate, children }) => (
  <CircularEconomyChartProvider
    {...chart}
    startDate={startDate}
    endDate={endDate}
  >
    <ComponentWithTitle {...chart}>
      <div className="col-12 pt-2 px-0 px-md-3">{children}</div>
    </ComponentWithTitle>
    <div className="dropdown-divider col-12 my-4" />
  </CircularEconomyChartProvider>
);

const SET_END_DATE = "SET_END_DATE";
const SET_START_DATE = "SET_START_DATE";

interface ActionInterface {
  payload: SetStateAction<Date>;
  type: string;
}

interface PeriodeStoreInterface extends PeriodeInterface {
  dispatch: Dispatch<ActionInterface>;
}

const getLastYear = (): Date => {
  const startDate = new Date();
  startDate.setFullYear(new Date().getFullYear() - 1);
  return startDate;
};

const defaultStore: PeriodeStoreInterface = {
  dispatch: () => {
    return;
  },
  endDate: new Date(),
  startDate: getLastYear(),
};

const reducer = (
  state: PeriodeStoreInterface = defaultStore,
  { payload, type }: ActionInterface
): PeriodeStoreInterface => {
  switch (type) {
    case SET_END_DATE: {
      return {
        ...state,
        endDate: payload as Date,
      };
    }
    case SET_START_DATE: {
      return {
        ...state,
        startDate: payload as Date,
      };
    }
    default:
      return state;
  }
};

export const Analysis: React.FC = () => {
  useLogged();
  const { itemFiltersSelected } = useContext(BaseStoreContext);
  const contract = (
    itemFiltersSelected.contract.length
      ? itemFiltersSelected.contract[0]
      : itemFiltersSelected.contract
  ) as OptionInterface;

  const [state, dispatch] = useReducer(reducer, defaultStore);
  const { startDate, endDate } = state;
  const [showCircularChart, setShowCircularChart] = useState<boolean>(false);
  const [charts, setCharts] = useState<(MultiChartProviderInterface & BaseChartsInterface)[]>([]);

  useEffect(() => {
    const roles = new Token().getDecoded().roles || [];
    setShowCircularChart(roles.includes("ROLE_CIRCULAR_ECONOMY"));
    const options: (MultiChartProviderInterface & BaseChartsInterface)[] = [
      {
        component: <Revenue />,
        title: 'pages.analysis.revenue',
        icon: 'poll',
        options: {
          Revenues: {
            label: 'charts.revenue.label',
            color: '#77B7FC',
            sum: true,
            unit: `${new Currency().getDecoded()?.label || '€'}`,
          },
          Unites: {
            label: `charts.revenue_units.${new Currency().getDecoded()?.value || 'eur'}`,
            color: '#030f40',
            type: 'line',
            unit: `${new Currency().getDecoded()?.label || '€'}/t`,
          },
        },
      },
    ];
    setCharts(options);
  }, []);

  return (
    <Layout withDisclaimer withFooter>
      <TypeSelectorProvider>
        <div className="col-12 d-flex ml-3 header-wrapper">
          <div className="">
            <SzDatePicker
              {...{
                format: "dd/MM/yyyy",
                dateRange: true,
                endDate,
                endMinDate: startDate,
                onChangeEndDate: (v): void =>
                  dispatch({ type: SET_END_DATE, payload: v as Date }),
                onChangeStartDate: (v): void =>
                  dispatch({ type: SET_START_DATE, payload: v as Date }),
                startDate,
              }}
            />
          </div>
          <FilterMaterials page={"analysis"} />
          <ExportAllChart startDate={startDate} endDate={endDate} />
          <TypeSelector className="h-100" />
        </div>
        {charts.map((chart, key) => (
          <Chart
            key={key}
            chart={chart}
            startDate={startDate}
            endDate={endDate}
          >
            {chart.component}
          </Chart>
        ))}
        {volumeCharts
          .filter(() => !(contract && contract.value === true))
          .map((chart, key) => (
            <VolumeChart
              key={key}
              chart={chart}
              startDate={startDate}
              endDate={endDate}
            >
              {chart.component}
            </VolumeChart>
          ))}
        {co2Charts
          .filter(() => !(contract && contract.value === true))
          .map((chart, key) => (
            <Co2Chart
              key={key}
              chart={chart}
              startDate={startDate}
              endDate={endDate}
            >
              {chart.component}
            </Co2Chart>
          ))}
        {showCircularChart &&
          circularEconomyCharts
            .filter(() => !(contract && contract.value === true))
            .map((chart, key) => (
              <CircularEconomyChart
                key={key}
                chart={chart}
                startDate={startDate}
                endDate={endDate}
              >
                {chart.component}
              </CircularEconomyChart>
            ))}
        {mixedCharts.map((chart, key) => (
          <MixedChartProvider
            {...chart}
            startDate={
              new Date(startDate.getFullYear(), startDate.getMonth(), 1)
            }
            endDate={endDate}
            key={key}
          >
            <MixedChart chart={chart}>{chart.component}</MixedChart>
          </MixedChartProvider>
        ))}
      </TypeSelectorProvider>
    </Layout>
  );
};
